import type { TDefaultModalSettings, TModalComponentMapping } from '../providers/ModalProvider';
import { Overlay } from "../components/Overlay";

export const modalComponentMapping: TModalComponentMapping = {};

export const defaultModalSettings: TDefaultModalSettings = {
  loader: ({ children }) => (
    <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        {children}
      </div>
    </div>
  ),
  overlay: Overlay,
  replaceCurrent: false,
  showOverlay: true
}