import "./Switch.css"

type TProps = {
    name: string;
};

export const Switch: React.FC<TProps> = ({ name }) => (
    <label className="c-switch">
        <input className="c-switch__input" name={name} type="checkbox" />
        <span className="c-switch__knob"></span>
    </label>
);