import { Header } from "../Header";
import { Panel } from "../Panel";
import { HomeIcon } from "../../icons";

type TProps = {
    sidebar?: () => React.ReactElement
}
type TIconProps = {
    className?: string
}

type TSidebarLinkProps = {
    icon: React.FC<TIconProps>
};

const SidebarLink: React.FC<TSidebarLinkProps> = ({ icon: Icon }) => (
    <a href="#" className="relative block px-5 py-6 text-center text-gray-700 transition-all duration-200 ease-linear">
        <span className="inline-block text-4xl leading-none">
            <Icon className="h-6 w-6" />
        </span>
    </a>
);

export const Layout: React.FC<TProps> = ({ children, sidebar: Sidebar }) => (
    <div className="app-background min-h-screen overflow-hidden flex flex-col">
        <Header />
        <aside className="fixed z-20 top-0 bottom-0 left-0 mt-20 leading-none duration-200 ease-linear transition-all">
            <div className="relative w-32 z-20 bg-white shadow h-full overflow-x-hidden overflow-y-auto opacity-100 transition-all duration-200 ease-linear divide-y divide-gray-300">
                <SidebarLink icon={() => <HomeIcon className="h-6 w-6" />} />
                <SidebarLink icon={() => <HomeIcon className="h-6 w-6" />} />
            </div>
        </aside>
        <main className="flex flex-grow p-2">
            <div className="w-60 hidden lg:block pr-2">
                {Sidebar && <Sidebar />}
            </div>
            <Panel className="flex-grow">{children}</Panel>
        </main>
    </div>
);