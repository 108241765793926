import type {
    TComponentQueueStateEntry,
    TComponentQueueStateReducerAction,
    TComponentQueueStateComponentMapping,
    TComponentQueueStateEntrySettings
} from '../componentQueue.types';

export function stackReducer<T>(mapping: TComponentQueueStateComponentMapping<T> , defaultSettings: TComponentQueueStateEntrySettings<T>) {
    return function (state: TComponentQueueStateEntry<T>[], action: TComponentQueueStateReducerAction<T>): TComponentQueueStateEntry<T>[] {

        switch (action.type) {
            case "close":
                return state.filter((_, i) => i !== 0);
            case "show":
                return [
                    ...state,
                    {
                        component: action.payload,
                        settings: {
                            ...defaultSettings,
                            ...action.settings
                        }
                    }
                ];
            case "trigger":
                const settings = {
                    ...defaultSettings,
                    ...action.settings
                };
                const triggerComponent = mapping[action.payload];
                const newEntry = {
                    component: triggerComponent?.component,
                    settings: {
                        ...settings,
                        ...triggerComponent?.settings
                    }
                };

                if (settings.replaceCurrent) {
                    return [...state.filter((_, i) => i !== 0), newEntry];
                }

                return [newEntry, ...state];
            default:
                return state;
        }
    };
};
