import React, { Suspense } from "react";
import { createPortal } from "react-dom";
import { createContext, useContextSelector } from 'use-context-selector';
import { useComponentQueueState } from "../../utils/componentQueue";
import type {
    TUseComponentQueueStateHookReturn,
    TComponentQueueStateComponentMapping,
    TComponentQueueStateEntry,
    TComponentQueueStateDefaultSettings
} from "../../utils/componentQueue";

export type TDefaultModalSettings = TComponentQueueStateDefaultSettings<TModalSettings>;
export type TModalComponentMapping = TComponentQueueStateComponentMapping<TModalSettings>;

type TModalProviderProps = {
    componentMapping: TModalComponentMapping;
    defaultSettings: TDefaultModalSettings;
    portalRoot: Element;
}

type TModalPortalProps = {
    defaultSettings: TDefaultModalSettings;
    modalQueue: TComponentQueueStateEntry<TModalSettings>[];
    portalRoot: Element;
};

type TModalSettings = {
  loader?: React.FC;
  overlay?: React.FC;
  showOverlay?: boolean;

};

const ModalContext = createContext<TUseComponentQueueStateHookReturn<TModalSettings>>({
    close: () => {},
    content: null,
    queue: [],
    show: (id, settings) => {
        debugger;
    },
    trigger: (id, settings) => {},
});

export const useCurrentModal = () => useContextSelector(ModalContext, context => context.content);

export const useShowModal = () => useContextSelector(ModalContext, context => context.show);
export const useTriggerModal = () => useContextSelector(ModalContext, context => context.trigger);
export const useCloseModal = () => useContextSelector(ModalContext, context => context.close);

export const ModalPortal: React.FC<TModalPortalProps> = ({ defaultSettings, modalQueue, portalRoot }) => {
  const currentModal = useCurrentModal();

  if (!currentModal) {
    return null;
  }

  const { component: ModalContent, settings } = currentModal;
  const Overlay = settings?.overlay || defaultSettings.overlay;
  const Loader = settings?.loader || defaultSettings.loader;

  const withOverlay = (children: React.ReactElement) => <Overlay>{children}</Overlay>;

  const showOverlay = settings?.showOverlay && modalQueue.length;

  return createPortal(
    <Suspense fallback={showOverlay ? withOverlay(<Loader />) : <Loader />}>
      {showOverlay ? withOverlay(<ModalContent />) : <ModalContent />}
    </Suspense>,
    portalRoot
  );
};

export const ModalProvider: React.FC<TModalProviderProps> = ({ children, componentMapping, defaultSettings, portalRoot }) => {
  const { trigger, close, content, queue, show } = useComponentQueueState<TModalSettings>(componentMapping, 'queue', defaultSettings);

  return (
    <ModalContext.Provider value={{ trigger, close, content, queue, show }}>
      {children}
      <ModalPortal defaultSettings={defaultSettings} modalQueue={queue} portalRoot={portalRoot} />
    </ModalContext.Provider>
  );
};