import { SearchIcon } from "../../icons";

type TItemIcon = {
    className?: string;
};

type TItem = {
    title: string;
    subtitle?: string;
    icon?: React.FC<TItemIcon>
};

type TProps = {
    title: string;
    items: TItem[];
    info: string;
};

export const Listing: React.FC<TProps> = ({ children, info, items, title }) => (
    <div className="c-listing-layout flex flex-col h-full bg-gray-100">
        <div className="p-4 shadow space-y-4 bg-white relative z-10">
            <div className="text-3xl text-gray-800 px-1">{title}</div>
            <div className="relative">
                <input placeholder="Search" className="w-full pl-3 pr-10 py-2 border border-gray-300 rounded-xl hover:border-gray-500 focus:outline-none focus:border-blue-500 transition-colors" />
                <button className="block w-7 h-7 text-center text-xl leading-0 absolute top-2 right-2 text-gray-400 focus:outline-none hover:text-gray-900 transition-colors"><SearchIcon className="h-6 w-6" /></button>
            </div>
            <div className="flex justify-between px-1">
                <div className="text-sm text-gray-500">{info}</div>
                <div className="text-sm text-gray-500 text-right">Filters</div>
            </div>
        </div>
        <div className="flex-grow relative z-0">
            <div className="absolute inset-0 overflow-y-auto">
                <div className="divide-y rounded-xl bg-white m-4 shadow">
                    {items.map(item => {
                        const ItemIcon = item.icon;
                        return (
                            <div className="flex items-center p-4 space-x-4">
                                <div className="rounded-full bg-blue-500 w-9 h-9"></div>
                                <div className="flex-grow">
                                    <div className="text-lg text-gray-700">{item.title}</div>
                                    <div className="text-sm text-gray-400">{item.subtitle}</div>
                                </div>
                                {ItemIcon && <ItemIcon className="h-6 w-6" /> }
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    </div>
);