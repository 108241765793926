import "./Layout2.css";

type TProps = {
    sidebar?: React.FC
}

export const Layout2: React.FC<TProps> = ({ children, sidebar: Sidebar }) => (
    <div className="c-layout2 h-screen overflow-hidden">
        <div>{Sidebar && <Sidebar /> }</div>
        <div>{children}</div>
    </div>
);