import Tippy from '@tippyjs/react';
import { Switch } from "../Switch";
import { MenuIcon, SearchIcon, ExpandIcon } from "../../icons";

type TProps = {
    className?: string;
}

const Logo: React.FC = () => (
    <span className="ml-2 font-heading font-bold text-2xl text-gray-700 uppercase">Jeeves</span>
);

const MobileMenuTrigger: React.FC = () => (
    <button type="button" className="m-0 p-0 text-3xl leading-none text-gray-600">
        <MenuIcon className="h-6 w-6" />
    </button>
);

const SearchField: React.FC = () => (
    <form className="hidden md:block ml-10" action="#">
        <label className="flex bg-white border border-gray-300 rounded-full w-full text-gray-700 overflow-hidden">
            <input type="text" className="appearance-none bg-white border border-gray-300 rounded w-full py-2 px-4 text-gray-700 border-none focus:outline-none focus:shadow-none focus:border-primary" placeholder="Search" />
            <span className="flex items-center pr-4">
                <button type="button" className="btn btn-link text-gray-300 hover:text-primary text-xl leading-none">
                    <SearchIcon className="h-6 w-6" />
                </button>
            </span>
        </label>
    </form>
);

const FullscreenToggle: React.FC = () => (
    <Tippy content="Toggle Fullscreen Mode">
        <button type="button" className="hidden lg:inline-block btn-link ml-3 px-2 text-2xl leading-none">
            <ExpandIcon className="h-6 w-6" />
        </button>
    </Tippy>
);

const DarkModeToggle: React.FC = () => (
    <Tippy content="Toggle Dark Mode">
        <div>
            <Switch name="dark_mode" />
        </div>
    </Tippy>
);

export const Header: React.FC<TProps> = ({ className }) => (
    <header className="fixed z-30 top-0 bg-white flex items-center w-full h-20 px-4 shadow">
        <MobileMenuTrigger />
        <Logo />
        <SearchField />
        <div className="flex items-center ml-auto">
            <DarkModeToggle />
            <FullscreenToggle />
        </div>
    </header>
);