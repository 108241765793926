import { RouteComponentProps } from "@reach/router"
import { Layout2, Listing } from "../../components/Layout";
import { MoreIcon } from "../../icons";

const Sidebar: React.FC = () => <div className="h-full bg-gray-900">Sidebar</div>;

const items = [
    {
        title: "David Calleja",
        subtitle: "79092967",
        icon: () => <MoreIcon className="h-6 w-6" />
    },
    {
        title: "Kenny D'Ugo",
        subtitle: "79315756",
        icon: () => <MoreIcon className="h-6 w-6" />
    },
    {
        title: "Daniela Calleja",
        subtitle: "79093609",
        icon: () => <MoreIcon className="h-6 w-6" />
    },
    {
        title: "David Calleja",
        subtitle: "79092967",
        icon: () => <MoreIcon className="h-6 w-6" />
    },
    {
        title: "Kenny D'Ugo",
        subtitle: "79315756",
        icon: () => <MoreIcon className="h-6 w-6" />
    },
    {
        title: "Daniela Calleja",
        subtitle: "79093609",
        icon: () => <MoreIcon className="h-6 w-6" />
    },
    {
        title: "David Calleja",
        subtitle: "79092967",
        icon: () => <MoreIcon className="h-6 w-6" />
    },
    {
        title: "Kenny D'Ugo",
        subtitle: "79315756",
        icon: () => <MoreIcon className="h-6 w-6" />
    },
    {
        title: "Daniela Calleja",
        subtitle: "79093609",
        icon: () => <MoreIcon className="h-6 w-6" />
    }
];

export const Home: React.FC<RouteComponentProps> = () => (
    <Layout2 sidebar={Sidebar}>
        <Listing title="Clients" info="Showing 350 clients" items={items} />
    </Layout2>
);