import { Toaster } from 'react-hot-toast';
import { Router } from "@reach/router";
import { ModalProvider } from './providers/ModalProvider';
import { modalComponentMapping, defaultModalSettings } from "./config/modals";
import { Clients, Services, Home } from "./pages";
import 'tippy.js/dist/tippy.css';

const modalPortalRoot = document.querySelector("#modal-root") as Element;

function InnerApp() {
  return (
      <Router>
        <Home path="/" />
        <Clients path="/clients" />
        <Services path="/services" />
      </Router>
  );
}

function App() {

  return (
    <ModalProvider componentMapping={modalComponentMapping} portalRoot={modalPortalRoot} defaultSettings={defaultModalSettings}>
      <InnerApp />
      <Toaster />
    </ModalProvider>
  );
}

export default App;
