import React, { useReducer } from "react";
import { queueReducer, stackReducer } from "./reducers";
import type {
  TComponentQueueStateComponentMapping,
  TComponentQueueStateReducer,
  TComponentQueueStateEntry,
  TComponentQueueStateEntrySettings
} from "./componentQueue.types";

export type TUseComponentQueueStateHookReturn<T> = {
  trigger: (id: string, settings?: TComponentQueueStateEntrySettings<T>) => void,
  close: () => void,
  show: (content: () => React.ReactElement, settings?: TComponentQueueStateEntrySettings<T>) => void,
  content: TComponentQueueStateEntry<T> | null, 
  queue: TComponentQueueStateEntry<T>[]
}

export function useComponentQueueState<T>(
  config: TComponentQueueStateComponentMapping<T>,
  reducerType: TComponentQueueStateReducer,
  defaultSettings: TComponentQueueStateEntrySettings<T>
): TUseComponentQueueStateHookReturn<T> {
  const reducerFactory = reducerType === "stack" ? stackReducer : queueReducer;
  const reducerWithConfig = reducerFactory<T>(config, defaultSettings);
  const [queue, dispatch] = useReducer(reducerWithConfig, []);

  const trigger = (id: string, settings?: TComponentQueueStateEntrySettings<T>) => {
    dispatch({
      type: 'trigger',
      payload: id,
      settings
    });
  };

  const close = () => {
    dispatch({
      type: 'close'
    });
  };

  const show = (content: () => React.ReactElement, settings?: TComponentQueueStateEntrySettings<T>) => {
    debugger;
    dispatch({
      type: 'show',
      payload: content,
      settings
    });
  };

  return {
    trigger,
    close,
    show,
    content: queue.length ? queue[queue.length - 1] : null,
    queue
  };
};
